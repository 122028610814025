import React, { useEffect } from 'react';
import Commonbutton from '../button/Button';
import "./static/error.scss"
import { ReactComponent as InfoCircle } from "../../components/inputfield/static/image/info-circle.svg";
import { useLocation } from 'react-router-dom';


// import { getWithExpiry, setWithExpiry } from "./boundaryControl"
function ErrorFallback({ error }) {
    const location = useLocation()

    // useEffect(() => {
    //     const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    //     if (error?.message && chunkFailedMessage.test(error.message)) {
    //         if (!getWithExpiry("chunk_failed")) {
    //             setWithExpiry("chunk_failed", "true", 10000);
    //             window.location.reload();
    //         }
    //     }
    // }, [error])
    const Reload =()=>{
        window.location.reload();
    }

    useEffect(()=>{
        console.log(location.pathname.includes(`/dashboard`))
    },[location])

    return (
        <div className={location.pathname.includes(`/dashboard`) ?"w_h_100" : 'h_100vh'}>
            <div className='d-flex w_h_100  align-items-center justify-content-center'>
                <div className='p-5 error_boundary card'>
                    <h2 className='text-center text_gray'>
                        OOPS!
                    </h2>
                    <h4 className='text_gray text-center my-4'>Something went wrong</h4>
                    <div className="action-block w-100 justify-content-center">
                        <Commonbutton
                            type="button"
                            onclickCallback={Reload}
                            // disabled={}
                            inputClassName="form-submit"
                            // isLoading={isLoading}
                            btnText="Reload"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorFallback
