// import config from "../config";
const ApiUrlServices = {
    SIGN_UP: "/auth/authentication/signup",
    SIGN_IN: "/auth/authentication/signin",
    CLIENT_FIRST_SIGN_IN: '/auth/authentication/admin-created-first-signin',
    CLIENT_FIRST_PASS_CHANGE: '/auth/authentication/admin-force-password-change',
    SIGN_OUT: "/auth/authentication/global-logout",
    SOCIAL_SIGN_IN: "/auth/authentication/social-signin",
    RESEND_CONFIRMATION_CODE: "/auth/authentication/resend-confirmation-code",
    FORGOT_PASSWORD: "/auth/authentication/forgot-password",
    SOCIAL_OAUTH_TOKEN_URL: "/oauth2/token",
    SET_NEW_PASSWORD: "/auth/authentication/set-password",
    GET_CONFIRMATION_CODE_VALIDITY: "/auth/authentication/get-confirmation-code-validity",
    CHANGE_PASSWORD: "/auth/authentication/change_password",
    CHECK_PAYMENT: (transaction_id) => `/payment/check_payment/${transaction_id}`,
    SOCIALS_UPDATE_LINKS: (userId) => `/auth/users/${userId}/update_links`,
    USER_DETAILS: '/auth/users/',
    CONTACT_US: `/subscription/feedback`,
    PROFILE_PIC_UPDATE: (userId) => `/auth/users/change_picture/${userId}`,
    REQUEST_MENTOR_APPROVAL: '/auth/authentication/request-mentor-approval',
    REGISTRATION_CONFIRM_SIGNUP: '/auth/authentication/confirm-signup',
    GET_CATEGORIES: `/category/cat-list?page=1&limit=100&parent_catid=`,
    GET_CATEGORIES_WITH_PAGINATION: (page, limit, parent_catid) => `/category/cat-list?page=${page}&limit=${limit}&parent_catid=${parent_catid}`,
    GET_CATEGORIES_DETAILS: (id) => `/category/cat-details/${id}`,
    GET_ADDRESS_ENTITY: `/address/get-address-entity`,
    GET_PREFERANCES: (userId, page, limit) => `/auth/user-preference/list/${userId}?page=${page || 1}&&limit=${limit || 10}`,
    ADD_PREFERENCES: `/auth/user-preference/add`,
    ADD_BATCH_PREFERENCE: `/auth/user-preference/batch-add`,
    UPDATE_PREFERENCES: `/auth/user-preference/update`,
    DELETE_PREFERENCE: (userId, id) => `/auth/users/${userId}/preferences/delete/${id}`,
    //
    GET_BID_DEPENDS_ON_PREF: (userId) => `/bid/bids/by_preference/${userId}`,
    // BID
    GET_BID_DETAIL: (bid_id) => `/bid/bids/${bid_id}`,
    GET_MY_BID_DETAIL: (user_id, my_bid_id) => `/bid/users/${user_id}/bids/${my_bid_id}`,
    UPDATE_BID_DETAIL: (bid_id) => `/bid/bids/${bid_id}`,
    GET_BID: `/bid/bids/search`,
    GET_BID_SUMMARY_COUNT: (bid_id) => `/bid/bids/${bid_id}/summary-count`,

    GET_MY_BID_FAV_BID: (user_id) => `/bid/users/${user_id}/bids/favorite-my`,

    ADD_BID_TO_FAV: (user_id) => `/bid/users/${user_id}/bids/favorite`,
    DELETE_BID_FROM_FAV: (user_id, favorite_bid_id) => `/bid/users/${user_id}/bids/favorite/${favorite_bid_id}`,
    ADD_MY_USER_BID: (user_id) => `/bid/users/${user_id}/bids`,

    GET_FAV_BID_BY_ID: (user_id) => `/bid/users/${user_id}/bids/favorite/list`,
    GET_MY_BID_BY_ID: (user_id) => `/bid/users/${user_id}/bids/list`,


    GET_USER_PROFILE: (userId) => `/auth/users/${userId}`,
    UPDATE_USER_PROFILE: (userId) => `/auth/users/${userId}`,
    UPDATE_ADVANCED_PROFILE: (userId) => `/auth/profile/advance/users/${userId}`,
    GET_ADVANCED_PROFILE: (userId) => `/auth/profile/advance/users/${userId}`,

    CREATE_NEW_PROJECT: (userId) => `/auth/client/users/${userId}/projects`,
    GET_PROJECTS: (userId) => `/auth/client/users/${userId}/projects/search`,
    GET_SINGLE_PROJECT: (userId, projectId) => `/auth/client/users/${userId}/projects/${projectId}`,
    UPDATE_SINGLE_PROJECT: (userId, projectId) => `/auth/client/users/${userId}/projects/${projectId}`,

    NEW_BID_BY_CATEGORY: '/category/stat',
    NEW_BID_BY_COUNTY: '/bid/actions/county',
    OVERALL_PER_DAY: (userId, from, to) => `/bid/actions/user/${userId}?from=${from}&to=${to}`,
    MOST_TRENDY_BID_CATEGORY: "/bid/stat/trendy/cat",
    MOST_TRENDY_BID_COUNTY: "/bid/stat/trendy/county",

    APPLY_COUPON: '/payment/coupon/coupon_apply',
    REMOVE_COUPON: '/payment/coupon/coupon_remove_from_cart',
    REGISTER_DEVICE: '/notification/register-device',
    UNREGISTER_DEVICE: '/notification/unregister-device',
    GET_ALL_CURRENCY: "/currency/currency-list",
    // USER_GET_ALL_COURSE_ENROLLMENT:(userId) => `/course/users/${userId}/enrolled?mode=free`,
    USER_GET_ALL_COURSE_ENROLLMENT: (userId, lepk = "", lesk = "", legsipk = "", legsisk = "") => `/course/users/${userId}/enrolled?lepk=${lepk}&lesk=${lesk}&legsipk=${legsipk}&legsisk=${legsisk}`,
    COURSE_ENROLLMENT: (courseId) => `/course/courses/${courseId}/enrollment`,
    GET_COURSE_DETAILS: (courseId) => `/course/courses/${courseId}`,
    GET_COURSE_DETAILS_PUBLIC: (courseId) => `/course/courses/${courseId}/public`,
    COURSE_UPDATE: (courseId) => `/course/courses/${courseId}`,
    GET_NOTIFICATION_SETTINGS: (userId) => `/notification/get-settings/${userId}`,
    CREATE_COURSE_QA: (courseId) => `/course/courses/${courseId}/qas`,
    CREATE_COURSE_NOTES: (courseId) => `/course/courses/${courseId}/notes`,
    CREATE_COURSE_NOTE_COMMENT: (courseId, notesId) => `/course/courses/${courseId}/notes/${notesId}/comments`,
    CREATE_COURSE_CHAPTER: (courseId) => `/course/courses/${courseId}/chapters`,
    ADD_COURSE_CHAPTER_LESSON: (courseId, chapter_id) => `/course/courses/${courseId}/chapters/${chapter_id}`,
    GET_PERMISSION_VIDEO_REQ: (courseId, chapter_id, idx, enrolled) => `/course/courses/get_permission_gr?course_id=${courseId}&chapter_id=${chapter_id}&lesson_index=${idx}&check_enrolled=${enrolled}`,
    GET_PERMISSION_VIDEO: `/course/courses/get_permission`,
    GET_PERMISSION_VIDEO_PUBLIC: `/course/courses/get_permission_public`,
    GET_ALL_COURSE_QA: (courseId, lepk, lesk) => `/course/courses/${courseId}/qas/all?lepk=${lepk}&lesk=${lesk}`,
    GET_ALL_COURSE_NOTES: (courseId, lepk, lesk) => `/course/courses/${courseId}/notes/all?lepk=${lepk}&lesk=${lesk}`,
    GET_ALL_COURSE_NOTE_COMMENT: (courseId, notesId, lepk = "", lesk = "") => `/course/courses/${courseId}/notes/${notesId}/comments/all?lepk=${lepk}&lesk=${lesk}`,
    GET_ALL_COURSE_CHAPTER: (courseId, lepk, lesk) => `/course/courses/${courseId}/chapters/all?lepk=${lepk}&lesk=${lesk}`,
    SET_COURSE_START_FLAG: (userId, courseId) => `/course/users/${userId}/enrolled/${courseId}`,
    SET_PRIMARY_EMAIL: "/auth/authentication/add_primary_email",
    RESEND_VERIFICATION_EMAIL: "/auth/authentication/resend_verification_email",
    VERIFY_USER_ATTRIBUTE: "/auth/authentication/verify_user_attribute",
    GET_COMPLETED_CHAPTERS: (userId, courseId) => `/course/users/${userId}/progress-completed/${courseId}/lessons`,
    SET_COMPLETED_LESSON: (userId, courseId) => `/course/users/${userId}/progress-completed/${courseId}`,
    GET_COURSES_BY_ID: `/course/courses/get-course-id-list`,
    GET_WISHLIST_BY_ID: (userId) => `/course/users/${userId}/favorite-courses`,
    REMOVE_COURSE_FROM_WISHLIST: (userId, courseId) => `/course/users/${userId}/favorite-courses/${courseId}`,
    ADD_COURSE_TO_WISHLIST: (userId) => `/course/users/${userId}/favorite-courses`,
    GET_CART_ENROLL_WISHLIST_BY_ID: (userId) => `/course/users/${userId}/bought-favorite-cart-courses`,
    COURSE_RATING: (courseId) => `/course/courses/${courseId}/reviews`,
    TRANSACTION_LIST: (userId) => `/payment/users/${userId}/transactions`,
    SALES_REPORT: (userId) => `/course/users/${userId}/sales-report`,
    GET_COURSE_REVIEWS_BY_ID: (courseId, reviewStatus) => `/course/courses/${courseId}/reviews/${reviewStatus}`,
    NEWSLETTER_SUBSCRIPTION: `/notification/newsletter-subscription`,
    CONTACT_US_SEND: `/notification/contact_us_save`,

    CUSTOM_KEYWORDS: `/auth/user-preference/custom-keyword`,
};

export default ApiUrlServices;
