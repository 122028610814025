const aws = require('./aws');
const baseLocation = require('./base_location');
const firebase = require('./firebase.json');
const payment = require('./payment.json');

module.exports = {
    host: 'localhost',
    port: 3001,
    DEFAULT_LANGUAGE: 'en', // for english en and for japanese use ja
    JSON_API_URL: process.env.REACT_APP_JSON_API_URL,
    API_URL: baseLocation,
    aws: baseLocation.aws,
    firebase: firebase,
    payment: payment,
    environment: baseLocation.environment,
    userRoles: ['Student', 'Instructor'],
    GOOGLE_SEARCH_CONSOLE_SITE_URL: process.env.REACT_APP_GOOGLE_SEARCH_CONSOLE_SITE_URL
};
