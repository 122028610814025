// import firebase from "../config/firebase";
import { saveAs } from 'file-saver';
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import config, { API_URL } from "../config";
import path from '../routes/path';
import { Storage } from "aws-amplify";
import { toast } from 'react-toastify';


export function setSessionStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
    return true
}

// export function getFirebaseToken() {
//     const msg = firebase.messaging();
//     return msg.requestPermission().then(() => {
//         return msg.getToken();
//     }).catch((err) => {
//         return err;
//     })
// }

export function capitalizeFirstLetter(string) {
    if (string === undefined) {
        return ''
    } else {
        return string.charAt(0).toUpperCase() + string.slice(1) + " ";
    }
}

export const secondToHours = (sec) => {
    return (sec / 3600).toFixed(2);
}

export const timeGet2 = (duration) => {
    const hours = `${Math.floor(duration / 3600)}`;
    let min = `${Math.floor((duration % 3600) / 60)}`;
    const secs = `${(duration % 3600) % 60}`;

    const finalString = `${getDigitsCard(hours, 'h')} ${getDigitsCard(min, 'm')} ${getDigitsCard(secs, "s")}`

    return finalString;
}

export const onlyHours = (duration) => {
    const hours = `${duration / 3600}`;

    return Number(duration) === 0 ? '0 Hour' : Number(hours).toFixed(2) + ' Hours';
}

const getDigitsCard = (hours, str) => {
    let finalString = `${hours === "0" ? "" : hours.length === 1 ? `0${hours}${str}` : `${hours}${str}`}`;
    if (!finalString && str === 's') {
        return "0s"
    }
    return finalString;
}


export const timeGet = (duration) => {
    const hours = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = (duration % 3600) % 60;

    const strHour = hours > 0 ? `${hours} Hours ` : "";
    const strMin = mins > 0 || hours > 0 ? `${mins} Mins ` : "";
    const strSec = secs > 0 || mins > 0 || hours > 0 ? `${secs} Secs` : "0 Sec";

    return strHour + strMin + strSec;
}

export const getMins = (duration) => {
    let digitTwo = false;
    let totalMins;
    const hours = Math.floor(duration / 3600);
    let min = Math.floor((duration % 3600) / 60);
    const secs = (duration % 3600) % 60;
    if (min.toString().length >= 2) {
        digitTwo = true;
        if (digitTwo) {
            totalMins = min + ":" + secs + "m"
        }
    } else {
        totalMins = "0" + min + ":" + secs + "m"

    }
    return totalMins;
}

export const isImage = (value) => {
    let new_value = value.split('.');

    const extension = new_value[new_value.length - 1].toLocaleLowerCase();
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
    if (imageTypes.indexOf(extension) !== -1) {
        return true;
    }
    return false;
}

export const isVideo = (value) => {
    let new_value = value.split('.');

    const extension = new_value[new_value.length - 1].toLocaleLowerCase();
    var videoTypes = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm', 'mkv', 'm3u8'];
    if (videoTypes.indexOf(extension) !== -1) {
        return true;
    }
    return false;
}

export const getUrlHls = (keyUrl) => {
    if (keyUrl.includes("ct-all")) {
        return keyUrl;
    }
    // const baseUrl = "https://d2wgekcgm4v3wq.cloudfront.net/";
    const baseUrl = API_URL.cloudfront_base_url;
    // let url = "public/courses/0d6b51a7f136dbc209c642755e114651e89079d4/chapter_content/asdf/16262488418670_001ModuleIntroductionBeginner.mp4"
    let url = keyUrl;

    let urlList = url.split(".");
    urlList.pop();

    let urlList1 = urlList.join(".");
    urlList = urlList1.split("/");

    const fileName = urlList.pop();

    url = urlList.join("/");

    const burl = `${baseUrl}${url}/hls/${fileName}_`;
    const ext = `_qvbr.m3u8`;

    // this.setState({BASE_URL: burl, EXT: ext});

    const finalUrl = `${burl}${"720p"}${ext}`;
    // console.log(finalUrl);
    return finalUrl;
}

export function isLetter(str) {
    if (str !== undefined) return str.length === 1 && str.match(/[a-z]/i);
}

export function isPdf(llink) {
    const fileName = llink.split("/").pop();
    let a = fileName.split(".");
    let c = a.pop();

    if (c === "temppdf") {
        return true
    }
    return false;
}

export const getPdfEx = (name) => {
    let a = name.split(".");
    a.pop();
    a.push("pdf");
    a = a.join(".");

    return a;
}

export const resolveFileName = (url) => {
    let name = String(url).split("/").pop();
    if (String(url).split("/").length === 1) {
        name = url;
        return url;
    }
    let fName = name.split("_");
    fName.splice(0, 1);
    fName = fName.join("_");

    if (isPdf(url)) {
        fName = getPdfEx(fName);
    }
    return fName;
}

export function downloader(url) {
    const name = resolveFileName(url);
    saveAs(url, name);
}

export function downloaderPdf(url) {
    let fileName = url.split("/").pop();
    fileName = resolveFileName(fileName);
    // let a = getPdfEx(fileName);
    // console.log(url, a);
    saveAs(url, fileName);
}

export function parseHtmlToDraft(data) {
    if (data !== undefined) {
        const contentBlock = htmlToDraft(data);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState)
    }
}

export function truncate(source, size) {
    let d = source.split(".")
    // return source.length > size ? source.slice(0, size - 1) + "…" : source;
    return d[0].length > size ? d[0].slice(0, size - 1) + "…" + d[1] : source;
}

export const truncateWords = (sentence, amount, tail) => {
    // console.log(sentence)
    // alert(sentence)
    const words = sentence.split(' ');

    if (amount >= words.length) {
        return sentence;
    }

    const truncated = words.slice(0, amount);
    return `${truncated.join(' ')}${tail}`;
};


export function shortLetterShow(str, size) {
    let result = str?.length > size ? str?.substr(0, size) + "...." : str;
    return result;
}

export const dateMonthYearCalculate = (timestamp) => {
    let d = new Date();
    let today = d.toLocaleString()
    let commmentDate = new Date(timestamp * 1000).toLocaleString();
    let currentDate = today.split(",")[0]
    let previousDate = commmentDate.split(",")[0]
    let diff = new Date(new Date(currentDate).getTime() - new Date(previousDate).getTime())
    let year = diff.getUTCFullYear() - 1970;
    let month = diff.getUTCMonth();
    let days = diff.getUTCDate() - 1;
    let result;
    if (year >= 2) {
        if (month >= 2) {
            if (days > 2) {
                result = `${year} years ${month} months ${days} days`
            } else {
                result = `${year} years ${month} months ${days} day`
            }
        } else if (month >= 1) {
            if (days > 2) {
                result = `${year} years ${month} month ${days} days`
            } else {
                result = `${year} years ${month} month ${days} day`
            }
        } else if (month >= 0) {
            if (days > 2) {
                result = `${year} years ${month} month ${days} days`
            } else {
                result = `${year} years ${month} month ${days} day`
            }
        }
    } else if (year === 1) {
        if (month >= 2) {
            if (days >= 2) {
                result = `${year} year ${month} months ${days} days`
            } else {
                result = `${year} year ${month} months ${days} day`
            }
        } else if (month >= 1 || month === 0) {
            if (days >= 2) {
                result = `${year} year ${month} month ${days} days`
            } else {
                result = `${year} year ${month} month ${days} day`
            }
        }
    } else if (year === 0) {
        if (month >= 2) {
            if (days >= 2) {
                result = `${month} months ${days} days`
            } else {
                result = `${month} months ${days} day`
            }
        } else if (month === 1) {
            if (days >= 2) {
                result = `${month} month ${days} days`
            } else {
                result = `${month} month ${days} day`
            }
        } else if (month === 0) {
            if (days >= 2) {
                result = `${days} days`
            } else {
                result = `${days} day`
            }
        }
    }
    // let result = `${years} ${months} ${days}`

    console.log(result)

    return result;

    console.log(diff.getUTCFullYear() - 1970); // Gives difference as year
    console.log(diff.getUTCMonth()); // Gives month count of difference
    console.log(diff.getUTCDate() - 1); // Gives day count of difference
}

const fileFormatValidator = (name, formatArr) => {
    let flag = false;
    formatArr.map(format => {
        if (name.includes(format)) {
            flag = true;
        }
    })

    return flag;
}

export const fileSizeValidator = (size, type, name, formatArr = []) => {
    const fileSizeMB = Math.round((size / 1024 / 1024));

    const baseFileSizeFormat = {
        "video": 500,
        "attachment": 50,
        "preview": 500,
        "image": 5
    }

    const filetype = {
        "video": [".mp4", ".m4v"],
        "attachment": [".zip", ".rar", ".tar"],
        "preview": [".mp4", ".m4v"],
        "image": ["image"]
    }

    if (name) {
        const formArr = formatArr.length ? formatArr : filetype[type];
        if (!fileFormatValidator(name, formArr)) {
            return "format_mismatch";
        }
    }

    if (!baseFileSizeFormat[type]) {
        return false;
    }

    if (fileSizeMB > baseFileSizeFormat[type]) {
        return false;
    }
    return true;
}

export const validateImageSize2 = async (file, cb) => {

    let img = new Image()
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
        cb(img)
    }
}


export const genericImageValidatorResizer = (file, config, setError, errObj, setImage, imageObj, e, onResizeImage, isNullErrorObj) => {

    const resolveAndSetErrors = (error) => {
        if (errObj) {
            setError(prev => ({
                ...prev,
                [errObj]: error
            }))
        } else {
            setError(error)
        }
    }

    const resolveAndSetImage = (editedFile) => {
        if (imageObj) {
            setImage(prev => ({
                ...prev,
                [imageObj]: editedFile
            }))
        } else {
            setImage(editedFile);
        }
    }

    const validation = fileSizeValidator(file.size, "image", file.type);
    if (validation === true) {
        validateImageSize2(file, (img) => {
            if (img.height >= config.height && img.width >= config.width) {
                resolveAndSetErrors("");
                validateImageSize2(file, async (img) => {
                    if (img.height > config.height || img.width > config.width) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.addEventListener("load", () => {
                            onResizeImage(reader.result, file.name);
                        });

                        return;
                    } else {
                        resolveAndSetImage(file);
                    }
                })
            } else {
                e.target.value = "";
                if (!isNullErrorObj) {
                    setImage({});
                } else {
                    setImage(null);
                }
                resolveAndSetErrors(`Image width and height can't be less than ${config.width}X${config.height}`);
            }
        })
    } else if (validation === false) {
        if (!isNullErrorObj) {
            setImage({});
        } else {
            setImage(null);
        }
        resolveAndSetErrors("Please select an image less than 5MB");
    } else if (validation === "format_mismatch") {
        e.target.value = "";
        resolveAndSetErrors(`Please select an image`)
    }
}

export const removeGarbageCharOnFileName = (fileName) => {
    const arr = fileName.split(".");
    const ext = arr.pop();

    let name = arr.join(".");
    const filterData = name.replace(/[^a-zA-Z0-9-_]/g, '');

    const finalStr = `${filterData}.${ext}`;
    console.log(finalStr, 'filename')
    return finalStr;
}

export const ErrorHandler = (error, formik, medium, setVerify) => {
    if (!error.response) {
        // formik.errors.email = "Error occured"
        toast.error("Something went wrong")
    }
    if (error.response.data.code === "USER_NOT_FOUND" || error.response.data.code === "ACCOUNT_DELETED" || error.response.data.code === "GENERIC") {
        medium === "email" ? formik.errors.email = "No user found with provided email" : formik.errors.email = "No user found with provided mobile";
        medium === "email" ? toast.error("No user found with provided email") : toast.error("No user found with provided mobile");
    }
    if (error.response.data.code === "SIGNIN_NOT_AUTHORIZED") {
        medium === "email" ? formik.errors.email = "Incorrect email or password" : formik.errors.email = "Incorrect mobile or password"
        medium === "email" ? toast.error("Incorrect email or password") : toast.error("Incorrect mobile or password")

    }
    if (error.response.data.code === "ACCOUNT_BLOCKED") {
        formik.errors.email = error.response.data.message
        toast.error(error.response.data.message)
    }
    if (error.response.data.code === "USER_NOT_CONFIRMED") {
        if (setVerify) setVerify(true)
        if (medium === "email") {
            formik.errors.email = "User account not confirmed! please check email for account confirmation link"
            toast.error("User account not confirmed! please check email for account confirmation link")
        } else {
            formik.errors.email = "User account not confirmed! please check sms for  account confirmation code"
            toast.error("User account not confirmed! please check sms for  account confirmation code")

        }
    }
}

export const getDate = (timeStamp, NotMultiple) => {
    if (!timeStamp || timeStamp === '0') return ''
    let multipleBYThousand = NotMultiple ? 1 : 1000
    let date = new Date(Number(timeStamp) * multipleBYThousand)
    let getDay = date.getDate();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let getMonth = month[date.getMonth()];
    let getYear = date.getFullYear();
    return `${getMonth} ${getDay}, ${getYear}`
}

export const countWords = (passedString) => {
    if (passedString === '') return 0
    passedString = passedString.replace(/(^\s*)|(\s*$)/gi, '');
    passedString = passedString.replace(/\s\s+/g, ' ');
    passedString = passedString.replace(/,/g, ' ');
    passedString = passedString.replace(/;/g, ' ');
    passedString = passedString.replace(/\//g, ' ');
    passedString = passedString.replace(/\\/g, ' ');
    passedString = passedString.replace(/{/g, ' ');
    passedString = passedString.replace(/}/g, ' ');
    passedString = passedString.replace(/\n/g, ' ');
    passedString = passedString.replace(/\./g, ' ');
    passedString = passedString.replace(/[\{\}]/g, ' ');
    passedString = passedString.replace(/[\(\)]/g, ' ');
    passedString = passedString.replace(/[[\]]/g, ' ');
    passedString = passedString.replace(/[ ]{2,}/gi, ' ');
    var countWordsBySpaces = passedString.split(' ').length;
    return countWordsBySpaces;
}


export const handleFileUpload = async (file, loader, path, setParcent, cb, setThumb_url) => {
    try {
        let thumb_url = '';
        if (file !== undefined && file.name) {
            loader(true)
            let file_name = removeGarbageCharOnFileName(file.name)
            let pathUrl = `${Date.now()}_${file_name}`;
            console.log('pathurl', pathUrl)
            await Storage.put(pathUrl, file,
                {
                    level: "public",
                    customPrefix: { public: path },
                    progressCallback(progress) {
                        console.log('from progress callback')
                        console.log(`Uploaded: ${Math.round(Number(progress.loaded / progress.total) * 100)}`);
                        setParcent(Math.round(Number(progress.loaded / progress.total) * 100));
                    }
                })
                .then(res => {
                    console.log(res)
                    thumb_url = config.aws.s3_base_url_with_folder_name + path + res.key;
                    console.log(thumb_url, 'thumb_url')
                }).catch(err => {
                    loader(false)
                    toast.error('File upload process failed')
                })
                .finally(() => loader(false))

            setThumb_url(thumb_url)
            setParcent(0)
            cb && cb()
            loader(false)
            return thumb_url

        } else {
            loader(false)
            return false
        }

    } catch (error) {
        toast.error('Something went wrong')
        loader(false)
    }
}

export const sliceString = (str, limit) => {
    if (typeof str !== 'string') return ''
    if (str === '' || str === 'null') return ''
    if (str.length < limit) return str
    return str.slice(0, limit) + '...'
}

export const handleUnWantedValue = (value, str) => {
    if ((value === '') || (value === null) || (value === undefined) || (value === 'null') || (value === ' ')) {
        return str
    } else {
        return value
    }
}

export const handleDate = (value1, value2) => {
    if ((value1 || value2) && (value1 !== '0' || value2 !== '0')) {
        return ` ${handleUnWantedValue(getDate(value1, false), 'N/A')} - ${handleUnWantedValue(getDate(value2, false), "N/A")} `
    } else {
        return "N/A"
    }
}
