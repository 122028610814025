export const increment_value = 'increment';
export const decrement_value = 'decrement';
export const users_request = 'users_request';
export const get_users_success = 'get_users_success';
export const get_users_failed = 'get_users_failed';

export const bid = {
    FETCHED_BID_DATA: 'FETCHED_BID_DATA',
    ADD_BID_TO_FAV_BID: 'ADD_BID_TO_FAV',
    ADD_BID_TO_MY_BID: 'ADD_BID_TO_MY_BID',
    REMOVE_FROM_FAV_BID: 'REMOVE_FROM_FAV_BID',
    REMOVE_FROM_MY_BID: 'REMOVE_FROM_MY_BID',
};
