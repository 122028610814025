import config from "./index";

export const S3_Pool_conf = {
    Auth: {
        identityPoolId: config.aws.identityPoolId,
        region: 'us-west-2',
    },
    Storage: {
        AWSS3: {
            bucket: config.aws.user_bucket,
            region: 'us-west-2',
        }
    }
};
