import {bid} from '../types';

const initialState = {
    my_bid: {},
    favorite_bid: {},
};

export default function (state = initialState, action) {
    console.log(state);
    console.log(action);
    switch (action.type) {
        case bid.ADD_BID_TO_FAV_BID:
            return {
                ...state,
                favorite_bid: {...state.favorite_bid, ...action.payload},
            };
        case bid.REMOVE_FROM_FAV_BID:
            const _favorite_bid = {...state.favorite_bid}
            // console.log('_favorite_bid',_favorite_bid)
            delete _favorite_bid[action.payload]
            return {
                ...state,
                favorite_bid: _favorite_bid
            }
        case bid.ADD_BID_TO_MY_BID:
            console.log(action.payload)
            return {
                ...state,
                my_bid: {...state.my_bid, ...action.payload},
            };


        case bid.FETCHED_BID_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
