import React from 'react';
import PropTypes from 'prop-types';
import Style from './CustomPageLoader.module.scss';
import BidDetail from "../../app/bid-detail/BidDetail";

export default function CustomPageLoader(props) {

    return (
        props.pageLoader ? <>
                <div className={`${Style.overlayed}`}/>
                <div className={`${Style.loader} ${props.className}`}/>
            </>
            : ""
    );
}

CustomPageLoader.propTypes = {
    pageLoader: PropTypes.bool.isRequired,
};