import React from 'react'
import bg_image from './static/images/Group.svg';
import currImage from './static/images/payment_method.png';
import './styles/footer.scss'
import path from "../../routes/path";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="footer-container" id="footer" data-aos="fade-up">
            <div style={{display: "none"}} className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 footer-links">
                            <h4>Company</h4>
                            <ul>
                                <li><Link to={path.about_us}>About Us</Link></li>
                                <li><Link to={path.career}>Careers</Link></li>
                                <li><Link to={path.our_strength}>Our Strength</Link></li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 footer-links">
                            <h4>Support</h4>
                            <ul>
                                <li><Link to={path.contact_us}>Contact Us</Link></li>
                                <li><a href="tel:+88 01844 616 717">Hotline</a></li>
                                <li><Link to={path.site_faq}>FAQ</Link></li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 footer-links">
                            <h4>Policies</h4>
                            <ul>
                                <li><Link to={path.privacy_policy}>Privacy Policy </Link></li>
                                <li><Link to={path.terms_services}>Terms of Services </Link></li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 footer-links">
                            <h4>Socials</h4>
                            <div className="social-links text-left ">
                                <a rel="noreferrer" target="_blank" href="https://www.facebook.com/CodersTrustSkill/" className="facebook">
                                    <i className="fa fa-facebook"/>
                                </a>

                                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/coderstrustskill/" className="linkedin">
                                    <i className="fa fa-linkedin"/>
                                </a>

                                <a rel="noreferrer" target="_blank" href="https://twitter.com/CodersTrust_net" className="twitter">
                                    <i className="fa fa-twitter"/>
                                </a>
                                <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCyNgmwHT1fN8V2xv6BAcu7g" className="youtube">
                                    <i className="fa fa-youtube"/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footer-bottom">
                <div className="container footer-bottom-inner">
                    <span className="footer-left">Copyright © 2022 BidAdvisor</span>
                    {/* <span className="footer-right">All rights Reserved</span> */}
                    <a href='https://shadhinlab.com/' ><span className="footer-right text-light">Powered by Shadhin Lab LLC, USA</span></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer

