import React from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import {useFormik} from "formik";
import CustomPageLoader from "../../components/customPageLoader";
import {ToastContainer,toast} from "react-toastify";
// import {DefaultLayout} from "../../containers/DefaultLayout";

import "./static/css/BidDetail.scss"
import Commonbutton from "../../components/button/Button";
import AddRemoveFavBid from "./components/add-remove-fav/AddRemoveFavBid";
import ApplyBid from "./components/apply-bid/ApplyBid";
import path from "../../routes/path";
import Mark from "mark.js";
import { get } from 'jquery';
import { getDate, handleUnWantedValue } from '../../helper/CommonFunctions';
import {BidDetailsSkeleton, BidTableSkeleton} from '../../components/Skeleton/BidDetailsSkeleton';
import { useContext } from 'react';
import { ThemeContext } from '../../ContextStore/Context';
import getMyBidFavBid from '../../helper/Services/getMyBidFavBid';
import { useState } from 'react';
import TableSkeleton from '../../components/Skeleton/TableSkeleton';


const BidDetail = props => {
    const {myBidAndFavoriteBid,setMyBidAndFavoriteBid} =useContext(ThemeContext)
    const [pageLoader, setPageLoader] = React.useState(false);
    const [getDetailLoader, setGetDetailLoader] = React.useState(true);
    let params = new URLSearchParams(document.location.search);
    let name = params.get("redirects-to")
    const [confirmationModal, setConfirmationModal] = React.useState(false);
    const [selectedBid, setSelectedBid] = React.useState({});
    const [toggleContent, setToggleContent] = React.useState(false);
    const {bid_id} = useParams();
    const location = useLocation();
    const [summaryLoader,setSummaryLoader]= useState(false)
    const sessionUserId = JSON.parse(localStorage.getItem('userData'))?.userId;

    const [bidSummaryData, setBidSummaryData] = React.useState([]);

    const history = useHistory();

    const getBidDetail = (url,payload) => {
        setPageLoader(true)
        setGetDetailLoader(true);
        const isPayLoad= payload? 'post':'get'
        return new Promise((resolve, reject) => {
            AxiosServices[isPayLoad](url,isPayLoad?payload:false, false)
                .then(data => resolve(data.data.data))
                .catch(error => reject(error))
                .finally(()=>setGetDetailLoader(false))

        });
    };

    React.useEffect(() => {
        // const bid_id = '0e506752f4f39dd9cfb579fcf95a40621da3e522'
        // const currentBid = bidsRedux.find(bid => bid._id === bid_id);
        let response = { data: { data: { userId: JSON.parse(localStorage.getItem("userData")).userId } } }
        getMyBidFavBid(response, false, false, false, false, setMyBidAndFavoriteBid)

        const url = (location.pathname === `${path.bid_detail}/${bid_id}`) ? ApiUrlServices.GET_BID_DETAIL(bid_id) : ApiUrlServices.GET_MY_BID_DETAIL(sessionUserId, bid_id)
        const payload = url === ApiUrlServices.GET_BID_DETAIL(bid_id)? { fields: [] } :false
        getBidDetail(url,payload).then(currentBid => {
            setSelectedBid(currentBid?._source);
            // fetchAddressInfo(currentBid?._source);
        }).catch(err => {
            toast.error("Something went wrong")
            console.log(err.response?.data.message,'bidDetail 64');
        }).finally(() => {
            // setGetDetailLoader(false);
        });
        

    }, []);

    React.useEffect(() => {
        const _bid_id = (location.pathname === `${path.bid_detail}/${bid_id}`) ? bid_id : bidFormik.values.bid_id
        if (_bid_id) {
            setPageLoader(true);
            console.log('summaryLoader');
            setSummaryLoader(true)
            AxiosServices.get(ApiUrlServices.GET_BID_SUMMARY_COUNT(_bid_id))
                .then(res => {
                    setBidSummaryData(res.data.data)
                    setPageLoader(false);
                })
                .catch(err => {
                    console.log(err.response)
                    setPageLoader(false);
                })
                .finally(() => setSummaryLoader(false))
        }
    }, [bid_id]);

    React.useEffect(() => {
        bidFormik.setValues({
            ...formikFields, ...selectedBid,
        });
    }, [selectedBid.bid_id]);

    const validateForm = (values) => {
        const errors = {};

        if (!values.status) {
            errors.status = 'Required!';
        }
        /*        if (values.status && !updateBidStatusList.includes(values.status)) {
                    errors.status = 'Required!';
                }*/
        // console.log(errors);
        return errors;
    };

    const handleSubmit = (values) => {
        setPageLoader(true);
        setConfirmationModal(false);
        const payload = {...values};

        AxiosServices.put(ApiUrlServices.UPDATE_BID_DETAIL(values.bid_id), payload).then(res => {

            toast.info('Bid Updated Successfully');
            setPageLoader(false);
            history.push(path.bid_search);
        }).catch(error => {
            toast.info('Error');
            console.log(error);
            setPageLoader(false);
        }).finally(() => {
            // setPageLoader(false);
            console.log('FINALLY');
        });
    };

    const formikFields = {
        bid_id: '',
        title: '',
        description: '',
        organization_name: '',
        published_date: '',
        deadline: '',
        cat_id: '',
        subcat_id: '',
        country_id: '',
        state_id: '',
        county_id: '',
        city_id: '',
        zipcode_id: '',
        status: '',
        contact_details: '',
        document_url: '',
        approved_by: '',
        created_at: '',
        updated_at: '',
        extracted_content: '',
        details_page_url: '',
    };
    const bidFormik = useFormik({
        initialValues: formikFields,
        validateOnChange: true,
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: handleSubmit,
    });

    let sorted = Object.entries(bidSummaryData).sort(([, v1], [, v2]) => v2 - v1).reduce((o, [k, v]) => ({
        ...o,
        [k]: v
    }), {});
    // console.log(bidSummaryData)
    // let keyData = Object.entries(bidSummaryData)
    // console.log(keyData)

    let SummaryDataKeys = Object.keys(bidSummaryData)

    let context = document.querySelector(".all-content"); // requires an element with class "context" to exist input-title
    let instance = new Mark(context);
    let description = document.querySelector(".description");
    let instanceDescription = new Mark(description);
    let inputTitle = document.querySelector(".title");
    let instanceInputTitle = new Mark(inputTitle);
    let options = {
        "accuracy": {
            "value": "exactly",
            "limiters": [",", "."]
        },
        "caseSensitive": false,
    };
    SummaryDataKeys.map((data, index) => {
        instanceInputTitle.mark(data, options)
        instanceDescription.mark(data, options);
        instance.mark(data, options);
    })

    const returnUrl=(url)=>{
        if(url.includes('https')){
            return url
        }else {
            return `https://${url}`
        }
    }


    return (
            <>
            <div className='bid-details-page'>
                <div className='container'>
                    
                        <>
                            <div className='row'>
                                <div className='bid-details-top w-100'>
                                    <div className='row align-self-center'>
                                        <div className="col-4">
                                            <div className="">
                                                <button className="back-button" onClick={() => history.push(name ? name : path.bid_search)}>
                                                    <i className="fa fa-long-arrow-left"
                                                        aria-hidden="true"></i> Back
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-7 offset-1  align-self-center'>
                                            <h2 className='profile-info-title'>{location.pathname === `${path.my_bid_detail}/${bid_id}` ? "My " : ""}Bid
                                                Details</h2>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='bid-details-bottom right w-100'>
                                {summaryLoader ? <BidDetailsSkeleton /> : (
                                    <div className="row">
                                        <div className="col-12 col-md-12 mx-auto col-lg-5 ">
                                            <h5 className="pb-3">Bid Summary Count</h5>
                                            <div className="bid-summary-table-data">
                                                <ul className="t-head">
                                                    <li>Keyword</li>
                                                    <li>Count</li>
                                                </ul>
                                                {
                                                    Object.entries(sorted).length ?
                                                        Object.entries(sorted).map((data, index) => (
                                                            <ul>
                                                                <li>{data[0]}</li>
                                                                <li>{data[1]}</li>
                                                            </ul>
                                                        ))
                                                        :
                                                        <div className="bid-summary-table-no-data">
                                                            <strong>No bid summary count found</strong>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                       <div className="col-12 col-lg-7 text-right status_label_full">
                                            {
                                                getDetailLoader ? <TableSkeleton height={55} count={1} /> : (
                                                    <ApplyBid
                                                        bid={selectedBid}
                                                        setPageLoader={setPageLoader}
                                                        btnText={'Submit'}
                                                    />
                                                )
                                            }                                            
                                        </div>                               
                                    </div>
                                )}
                                    
                                </div>
                               
                                
                            </div>
                            {console.log('detailLoader',getDetailLoader)}
                        {
                            getDetailLoader ? <div className='w-100'><BidTableSkeleton /> </div>: (selectedBid.bid_id ?(
                                <div className='row'>
                                    <div className='biddetails-content full-content w-100'>
                                        <div className="bid-details-add-fav-btn">
                                            <AddRemoveFavBid
                                                bid={selectedBid}
                                                setPageLoader={setPageLoader}
                                            />
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-check-square"></i> Title</strong>
                                            <p className="title main-title">{selectedBid.title}</p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-tags"></i> Category</strong>
                                            <p className="title">{bidFormik.values.cat_name}</p>
                                        </div>
                                        <div className={location.pathname.includes(path.my_bid_detail) ? 'd-none' : 'single-itm status'}>
                                            <strong><i class="fa fa-check-square"></i> Status</strong>
                                            <p className={selectedBid.my_status}>{handleUnWantedValue(selectedBid.status, "N/A")}</p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-file-text"></i> Description</strong>
                                            <p className="title">{selectedBid.description}</p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-link"></i> Original Source</strong>
                                            <p className="title">
                                                {
                                                    bidFormik.values.details_page_url?(
                                                        <a className={bidFormik.values.details_page_url ? 'button-1 src-btn' : 'button-1 in_active_link src-btn'} href={bidFormik.values.details_page_url}
                                                            target={'_blank'}>
                                                            <i className="fa fa-external-link"></i>
                                                            Original Site Url
                                                        </a>
                                                    ) : 'N/A'
                                                }
                                                
                                            </p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-file-text"></i> Document</strong>
                                            <p className="title">
                                                {
                                                    bidFormik.values.document_url ? (
                                                        <a className={bidFormik.values.document_url ? 'button-1 src-btn' : 'in_active_link button-1 src-btn'} href={returnUrl(bidFormik.values.document_url)}
                                                            target={'_blank'}>Open
                                                            Bid Document
                                                        </a>
                                                    ) : 'N/A'
                                                }
                                                
                                            </p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-clock-o"></i> Deadline</strong>
                                            <p className="title">
                                                <span>
                                                    {
                                                        selectedBid.published_date <= selectedBid.deadline ?
                                                            handleUnWantedValue(getDate(selectedBid.deadline, true), "N/A")
                                                            : "N/A"
                                                    }
                                                    {/* {selectedBid.deadline ? new Date(selectedBid.deadline).getDate().toString() : 'DD'} */}
                                                </span>
                                                {/* <span>{selectedBid.deadline ? new Date(selectedBid.deadline).toLocaleString('default', { month: 'long' }).toString() : 'MM'}</span>
                                                <span>{selectedBid.deadline ? new Date(selectedBid.deadline).getFullYear().toString() : 'YYYY'}</span> */}
                                            </p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-globe"></i> Country</strong>
                                            <p className="title">{selectedBid.country_name}</p>
                                        </div>
                                        <div className='single-itm'>
                                            <strong><i class="fa fa-map-marker"></i> State</strong>
                                            <p className="title">{selectedBid.state_name}</p>
                                        </div>
                                        <div className='single-itm mb-none'>
                                            <strong><i class="fa fa-location-arrow"></i> County</strong>
                                            <p className="title">{selectedBid.county_name}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : <strong className="w-100 text-center no-bid-found-text">Could not fetch bid, Please Try again later</strong>)
                        }
                                                          
                        </>
                    
                </div>
            </div>
        </>
        
    );
};

BidDetail.propTypes = {};
export default BidDetail