import React from 'react';
import PropTypes from 'prop-types';
import Commonbutton from "../../../../components/button/Button";
import {useFormik} from "formik";
import AxiosServices from "../../../../networks/AxiosService";
import ApiUrlServices from "../../../../networks/ApiUrlServices";
import { toast, ToastContainer } from "react-toastify";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import constants from "../../../../constants";
import {bid} from "../../../../services/types";
import { useState } from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../../../ContextStore/Context';
import getMyBidFavBid from '../../../../helper/Services/getMyBidFavBid';
import { data } from 'jquery';


const AddRemoveFavBid = (props) => {
    const { setMyBidAndFavoriteBid, myBidAndFavoriteBid } = useContext(ThemeContext)
    const [btnLoader,setBtnLoader]= useState(false)
    const sessionUserId = JSON.parse(localStorage.getItem('userData')).userId;
    const history = useHistory();
    const dispatch = useDispatch();
    const userBidRedux = useSelector(state => state.userBidReducer)
    const myFavBidRedux = userBidRedux[constants.localStorageKey.favBidData]
    const [isFavBid, setIsFavBid] = React.useState(false);
    const {btnDisabled,setBtnDisabled} = props

    React.useEffect(() => {
        favBidFormik.setValues({...formikFields, ...props.bid})
        setIsFavBid(props.bid?.favorite_bid_id || myBidAndFavoriteBid.favorite_bid?.[props.bid.bid_id]);
        console.log("context favorit bid", myFavBidRedux[props.bid.bid_id] ,props.bid?.favorite_bid_id ,myBidAndFavoriteBid.favorite_bid?.[props.bid.bid_id])
        
    }, [props.bid.bid_id,myBidAndFavoriteBid])

    const formikFields = {
        bid_id: "",
        organization_name: "",
        title: "",
        description: "",
        published_date: 0,
        deadline: 0,
        contact_details: "",
        cat_id: '',
        subcat_id: '',
        country_id: '',
        state_id: '',
        county_id: '',
        city_id: '',
        zipcode_id: '',
        status: '',
        county_name:'',
        state_name:''
    }

    const add_remove_fav = async (values) => {
        // props.setPageLoader(true);
        setBtnLoader(true)
        setBtnDisabled && setBtnDisabled(true)
        const payload = {
            bid_id: values.bid_id,
            organization_name: values.organization_name,
            title: values.title,
            description: values.description,
            published_date: values.published_date,
            deadline: values.deadline,
            contact_details: values.contact_details,
            cat_id: values.cat_id,
            subcat_id: values.subcat_id,
            country_id: values.country_id,
            state_id: values.state_id,
            county_id: values.county_id,
            city_id: values.city_id,
            zipcode_id: values.zipcode_id,
            status: values.status,
            county_name: values.county_name,
            state_name: values.state_name
        }
        let favourite_id = props.bid?.favorite_bid_id || myBidAndFavoriteBid.favorite_bid?.[props.bid.bid_id];

        const url = isFavBid ? ApiUrlServices.DELETE_BID_FROM_FAV(sessionUserId, favourite_id) : ApiUrlServices.ADD_BID_TO_FAV(sessionUserId);
        const getMethods = (name) => {
            const methods = {
                'delete': AxiosServices.remove,
                'post': AxiosServices.post,
            }
            return methods[name]
        }
        console.log('props.data',props.data)
      
        getMethods(isFavBid ? "delete" : "post")(url, payload, false)
            .then(res => {
                // props.setPageLoader(false)
                setBtnLoader(false)
                setBtnDisabled && setBtnDisabled(false)
                if (isFavBid) {
                    setIsFavBid(false)
                    toast.success("Removed from favorite list")
                    if (props.data){
                        let newData = props.data.filter((v) => v._source.bid_id !== props.bid.bid_id)
                        console.log('newData',newData)
                        props.setData(newData)
                        props.setTotalCountBid(props.totalCountBid-1)
                        let newTotalPage = Math.ceil((props.totalCountBid - 1) / props.limit)
                        if ((newTotalPage!== props.totalPage) && (newTotalPage!==0)){
                            console.log('rendering form inside')
                            props.setCPage(props.cPage-1)
                        }
                        props.setTotalPage(newTotalPage)
                    }
                    let newData= {...myBidAndFavoriteBid}
                    delete newData.favorite_bid?.[props.bid.bid_id]
                    myBidAndFavoriteBid && setMyBidAndFavoriteBid(newData)
                    dispatch({
                        type: bid.REMOVE_FROM_FAV_BID,
                        payload: props.bid.bid_id,
                    })
                    // props.onRemoveCallback && props.onRemoveCallback();
                } else {
                    toast.success("Added to favorite list")
                    setIsFavBid(true)
                    let newData = { ...myBidAndFavoriteBid }
                    newData.favorite_bid[props.bid.bid_id] = res.data.data.favorite_bid_id
                    setMyBidAndFavoriteBid(newData)
                    dispatch({
                        type: bid.ADD_BID_TO_FAV_BID,
                        payload: {[props.bid.bid_id]: res.data.data.favorite_bid_id}
                    })
                }
            }).catch(err => {
                toast.error(err.response?.data.message)
                // props.setPageLoader(false);
                setBtnDisabled && setBtnLoader(false)
                setBtnDisabled(false)
                console.log(err)
        })

    }

    const validateForm = (values) => {
        const errors = {};
        return errors;
    }

    const favBidFormik = useFormik({
        initialValues: {},
        validateOnChange: true,
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: add_remove_fav,
    })

    return (
        <>
            <Commonbutton
                type="button"
                disabled={btnDisabled ? btnDisabled : favBidFormik.isSubmitting}
                onclickCallback={favBidFormik.handleSubmit}
                inputClassName={isFavBid ? "button-1 m-0 fav_active" : "button-1 m-0"}
                isLoading={btnLoader}
                btnText={isFavBid ? "Remove Favorite" : "Make Favorite"}
            />
        </>
    );
}
AddRemoveFavBid.propTypes = {
    bid: PropTypes.object.isRequired,
    setPageLoader: PropTypes.func.isRequired,
};

export default AddRemoveFavBid;
