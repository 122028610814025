import React, { lazy, Suspense } from "react";
import AuthLayout from "../containers/AuthLayout";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import { Error404 } from "../app/error-pages/404";
import CustomPageLoader from "../components/customPageLoader";
import { ManagePrivateAccess } from "./ManageAccess";
import path from '../routes/path'
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorBoundary/ErrorBoundary";
const TheAuthLayout = lazy(() => import("../containers/AuthLayout/AuthLayout"));
const TheDefaultLayOut = lazy(() => import("../containers/DefaultLayout/index"));
const TheCommonLayout = lazy(() => import("../containers/DefaultLayout/CommonLayout"));


export const MainRoutes = () => {

    return (
        <ScrollToTop>
            <ToastContainer />
            <Suspense fallback={<CustomPageLoader pageLoader={true} />}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Switch>
                    <Redirect exact from={'/'} to={path.client} />
                    <Route path='/client' name="client" render={props => <TheCommonLayout {...props} />} />
                    <Redirect exact from={path.home} to={path.dashboard} />
                    <Route path={path.home} name="Dashboard" render={props => <TheDefaultLayOut {...props} />} />
                    <Route path="/" name="Auth Layout" render={props => <TheAuthLayout {...props} />} />
                </Switch>
                </ErrorBoundary>
            </Suspense>
        </ScrollToTop>
    );
};

