const path = {
  // pages
  client:'/client/home',
  about_us:'/client/about',
  contact:'/client/contact',

  home: '/dashboard',
  social_redirect_url: '/social-login-int',
  registration_confirmation : "/registration-confirmation",
  registration_phone_confirmation : "/registration-phone-confirmation",
  login: '/login',
  login_phone: '/login-phone',
  forgot_password: '/forget-password',
  forgot_password_phone: '/forget-password-phone',
  forgot_password_confirm: '/forget-password-confirm',
  forgot_password_phone_confirm: '/forget-password-phone-confirmation',
  reset_password : '/reset-password',
  change_password:'/change-password',
  reset_password_phone : '/reset-password-phone',
  registration: '/registration',
  registration_verify_email: "/verify-email",
  registration_verify_phone: "/verify-phone",
  registration_phone: '/registration-phone',
  confirm_signup: '/confirm-signup',
  mentor_registration :'/mentor-registration',
  mentor_confirmation :'/mentor-confirmation',
  mentor_registration_phone_confirmation :'/registration-phone-confirmation',

  profile: "/dashboard/profile",
  bid_search: "/dashboard/search-bid",
  previous_project: "/dashboard/client-project",
  add_project: "/dashboard/add-client-project",
  view_project: "/dashboard/view-client-project",
  student_public_profile: "/student-public-profile",
  all_course: "/all-course",
  mentor_courses: "/instructor-courses",
  my_cart: "/my-cart",
  course_details: "/course-details",
  course_details_new: "/course-details-new",
  course_enroll: "/course-enroll",
  notification_settings: "/notification-settings",
  instructor_details: '/instructor-details',
  course_list: "/course-list",
  purchase_history: "/purchase-history",
  order_details: "/order-details",
  dashboard: "/dashboard/dashboard",
  career: "/career",
  our_strength: "/our-strength",
  terms_services: "/terms-services",
  refunc_policy: "/refund-policy",
  privacy_policy: "/privacy-policy",
  site_faq: "/site-faq",
  contact_us: '/contact-us',

  not_found : "/not-found",
  //BID
  my_bids: "/dashboard/my-bids",
  my_bid_detail: `/dashboard/my-bids/detail`,
  bid_detail: `/dashboard/bid-detail`,
  favourite_bid: `/dashboard/favourite-bids`,


  //s3 bucket route
  bucket_profile:'profile/',
  bucket_client_project:'client_project_files/'

};

export default path;
