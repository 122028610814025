import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import default_profile_picture from "./static/image/man.png";
import profile_picture from "./static/image/man2.svg";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import config from "../../config";
import path from "../../routes/path";
import CustomPageLoader from "../customPageLoader";
import './styles/header.scss';
import { toast, ToastContainer } from 'react-toastify';
import Commonbutton from "../button/Button";
import {ReactComponent as SearchIcon} from "./static/image/Search.svg";
import Cross from "./static/image/error.png";
import SiteLogo from './static/image/Logo.png'
import CountDown from '../../pages/Home/CountDown';

const Header = (props) => {
    const [pageLoader, setPageLoader] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [cardImage, setCardImage] = useState(null);
    const [totalCartItem, setTotalCartItem] = useState(Number(localStorage.getItem("totalCartItem")));
    const [userData, setUserData] = useState({})
    const [courseName, setCourseName] = useState("");
    const history = useHistory();
     const location = useLocation()

    useEffect(() => {
        setCardImage(props.profilePicture)
    }, [props.profilePicture])

    useEffect(() => {
        if (props.cartCountIncrement) {
            setTotalCartItem(totalCartItem + 1)
            localStorage.setItem("totalCartItem", JSON.stringify(totalCartItem + 1));
        }
    }, [props.cartCountIncrement])

    useEffect(() => {
        let sessionUserData = JSON.parse(localStorage.getItem('userData'));
        if (sessionUserData === undefined || sessionUserData === null) {
            setCardImage(profile_picture)
            setLoggedIn(false)
            setUserData({})
        } else {
            (sessionUserData.profile_picture === undefined || sessionUserData.profile_picture === '') ? setCardImage(default_profile_picture) : setCardImage(sessionUserData.profile_picture)
            setLoggedIn(true)
            setUserData(sessionUserData)
        }

    }, [])


    function logout(e) {
        setPageLoader(true)
        e.preventDefault()
        localStorage.clear()
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        if (userData['signinSource'] === 'REGULAR') {
            let payload = {
                accessToken: userData.accessToken
            }

            AxiosServices.post(ApiUrlServices.SIGN_OUT, payload, false)
                .then((response) => {
                    console.log(response);
                    history.push(
                        {pathname: path.login}
                    )
                })
                .catch((error) => {
                    console.log(error)
                    history.push(
                        {pathname: path.login}
                    )
                });
        } else {
            if (userData['signinSource'] === 'AUTH0') {
                window.location.href = "https://ctapi.coderstrust.net/prod/auth/authentication/linkedin-logout/tPeQKj9T9wtx2rrHIIUShty70tjgij8a/coderstrust-login/"

            } else {
                window.location.href =
                    config.API_URL.social_api_base_url +
                    `/logout?client_id=` +
                    config.aws.cognito_app_client_id +
                    `&logout_uri=` +
                    config.API_URL.web_base_url +path.login+
                    '/';
            }
        }

    }

    const userExistOrNot=()=>{
        let user = JSON.parse(localStorage.getItem('userData'))?.userId
        return user? true : false
    }

    const searchCourseByName = () => {
        
    }

    const resetSearchField = () => {
        setCourseName("");
    }

    useEffect(()=>{
        console.log('from the header')
    },[])

    const SearchSection = () => {
        return (
            <div className="dropdown search-top-bar">
                <button className="btn btn-secondary dropdown-toggle" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                    <SearchIcon/>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {SearchSection()}
                    <div className="search-container navbar-top">
                        <form autoComplete="off"
                              onSubmit={e => {
                                  e.preventDefault();
                                  searchCourseByName();
                              }}>
                            <input
                                className="header-search"
                                type="text"
                                placeholder="Search Course"
                                value={courseName}
                                onChange={
                                    e => {
                                        setCourseName(e.target.value);
                                    }
                                }
                            />
                            {
                                courseName.length === 0 ? null :
                                    <button onClick={resetSearchField}
                                            className="close-icon1"
                                            type="reset">
                                        <img src={Cross} alt=""/>
                                    </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
    const [menuaddClass, setMenuaddClass] = useState(false);
    useEffect(()=>{
        
    },[location.pathname])
    return (
        <>
            <ToastContainer/>
            <CustomPageLoader pageLoader={pageLoader}/>
            <div className={menuaddClass ? "off-canvas active" : "off-canvas"} onClick={() => setMenuaddClass(!menuaddClass)}/>
            <div className={menuaddClass ? "bid-mobile-menu-sidebar active" : "bid-mobile-menu-sidebar"}>
                <div className="bid-mobile-menu-sidebar-full">
                    <ul>
                        <li>
                            <Link to={path.client} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to={path.about_us} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to={path.contact} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                Contact
                            </Link>
                        </li>
                        <li className={userExistOrNot()?'':'d-none'}>
                            <Link to={path.dashboard} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                Dashboard
                            </Link>
                        </li>
                        <li className={userExistOrNot()?'d-none':''}>
                            <Link to={path.login} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                Login
                            </Link>
                        </li>
                        <li className={userExistOrNot()?'d-none':'' }>
                            <Link to={path.registration} exact activeClassName="current selected"
                             onClick={() => setMenuaddClass(false)}
                            >
                                Registration
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className={location.pathname?.includes(path.client)?'header-nav main-header-area container countdown-active':'header-nav main-header-area container'}>

                    <div className="logo-site navbar-brand">
                        <Link to={path.home}>
                            <span>
                                <img src={SiteLogo}  alt=""/>
                            </span>
                        </Link>
                    </div>
                    
                    {/* <CountDown /> */}
                    
                    
                    
                    <div className="header-right-menu-bar">
                        <div className={menuaddClass ? "mobile-nav-icon active" : "mobile-nav-icon"}
                                         onClick={() => setMenuaddClass(!menuaddClass)}>
                            <div class="div">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
    
                            <div style={{display: "none"}} className="search-container navbar-top search-top">
                                <form autoComplete="off"
                                      onSubmit={e => {
                                          e.preventDefault();
                                      }}>
                                    <input
                                        className="header-search"
                                        type="text"
                                        placeholder="Search Bid"
                                    />
                                    {
                                        courseName.length === 0 ? null :
                                            <button onClick={resetSearchField}
                                                    className="close-icon1"
                                                    type="reset">
                                                <img src={Cross} alt=""/>
                                            </button>
                                    }
                                </form>
                            </div>
    
                            <div style={{display: "none"}} className="collapse navbar-collapse navbar-middle home"
                                 id="bs-example-navbar-collapse-1 home">
                                <ul style={{display: "none"}} className="nav navbar-nav ">
                                    <li>
                                        <NavLink to={path.home} exact activeClassName="current selected">
                                            Home
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
    
                            <div className="collapse navbar-collapse navbar-middle "
                                 id="bs-example-navbar-collapse-1 course">
                                <ul style={{display: "none"}} className="nav navbar-nav">
                                    <li>
                                        <NavLink to={path.bid_search} exact activeClassName="current selected">
                                            Bids
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
    
                            <div className="collapse navbar-collapse navbar-middle "
                                 id="bs-example-navbar-collapse-1 about-us">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <NavLink to={path.client} exact activeClassName="current selected">
                                            Home
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="collapse navbar-collapse navbar-middle "
                                 id="bs-example-navbar-collapse-1 about-us">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <NavLink to={path.about_us} exact activeClassName="current selected">
                                            About
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
    
                            <div className="collapse navbar-collapse navbar-middle "
                                 id="bs-example-navbar-collapse-1 contact-us">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <NavLink to={path.contact} exact activeClassName="current selected">
                                            Contact
                                        </NavLink>
                                    </li>
                                </ul>
                            </div> 
                            <div className={userExistOrNot() ? "collapse navbar-collapse navbar-middle ":'d-none'}
                                 id="bs-example-navbar-collapse-1 contact-us">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <NavLink to={path.dashboard} exact activeClassName="current selected">
                                            Dashboard
                                        </NavLink>
                                    </li>
                                </ul>
                            </div> 
    
                            <ul className="nav navbar-nav pull-right top-nav navbar-top">
                                {
                                    JSON.parse(localStorage.getItem('userData'))?.userId ? <>
                                        <div className="dropdown search-top-bar " style={{display: "none"}}>
                                            <button className="btn btn-secondary dropdown-toggle" type="button"
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                <SearchIcon/>
                                            </button>
                                            <div className="dropdown-menu  login-navbar"
                                                 aria-labelledby="dropdownMenuButton">
                                            </div>
                                        </div>
                                    </> : ''
                                }
    
    
                                <li className="dropdown ">
    
                                    {
                                        JSON.parse(localStorage.getItem('userData'))?.userId ?
                                            <>
                                                <a href="#" className="dropdown-toggle profile-img"
                                                   data-toggle="dropdown"
                                                   aria-expanded="true">
                                                    <img alt="" className="img-circle"
                                                         src={cardImage}
                                                         width="30"/>
                                                </a>
                                                <ul className="dropdown-menu profile-sub">
                                                    <li>
                                                        <Link to={path.profile}>
                                                            <i className="fa fa-fw fa-user"/> My Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={path.my_bids}>
                                                            <i className="fa fa-fw fa-book"/> My Bids
                                                        </Link>
                                                    </li>
                                                    <li className="divider"/>
                                                    <li onClick={logout} style={{cursor: "pointer"}}>
                                                        <a href={void (0)}>
                                                            <i className="fa fa-fw fa-power-off"/>
                                                            Logout
    
                                                        </a>
                                                    </li>
                                                </ul>
                                            </>
                                            :
                                            <div className="menu-register-btn-header-mobile-hide">
                                                <div className="d-flex ">
    
                                                    <div className="dropdown search-top-bar search-box-login"
                                                         style={{display: "none", marginRight: "8px"}}>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                                                id="dropdownMenuButton" data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false">
                                                            <SearchIcon/>
                                                        </button>
                                                        <div className="dropdown-menu search-input-field"
                                                             aria-labelledby="dropdownMenuButton">
                                                            <div className="search-container navbar-top">
                                                                <form autoComplete="off"
                                                                      onSubmit={e => {
                                                                          e.preventDefault();
                                                                          searchCourseByName();
                                                                      }}>
                                                                    <input
                                                                        className="header-search"
                                                                        type="text"
                                                                        placeholder="Search Course"
                                                                        value={courseName}
                                                                        onChange={
                                                                            e => {
                                                                                setCourseName(e.target.value);
                                                                            }
                                                                        }
                                                                    />
                                                                    {
                                                                        courseName.length === 0 ? null :
                                                                            <button onClick={resetSearchField}
                                                                                    className="close-icon1"
                                                                                    type="reset">
                                                                                <img src={Cross} alt=""/>
                                                                            </button>
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Commonbutton
                                                        type="button"
                                                        onclickCallback={() => {
                                                            history.push(path.registration)
                                                        }}
                                                        btnText="Registration"
                                                        inputClassName="registration-btn auth-registration-btn"

                                                    />
                                                    <Commonbutton
                                                        type="button"
                                                        onclickCallback={() => {
                                                            history.push(path.login)
                                                        }}
                                                        btnText="Login"
                                                        inputClassName="login-btn"

                                                    />
 
                                                </div>
                                            </div>
                                            
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
