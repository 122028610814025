import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrlServices from '../networks/ApiUrlServices';
import AxiosServices from '../networks/AxiosService';

export const ThemeContext = React.createContext();

const Context = ({ children }) => {
    const [categories,setCategories] = useState([])
    const [myBidAndFavoriteBid, setMyBidAndFavoriteBid] = useState({})
    const [editedUser,seteditedUser] = useState(false)
    useEffect(()=>console.log('context',editedUser),[editedUser])

    return (
        <ThemeContext.Provider value={{ categories, setCategories, myBidAndFavoriteBid, setMyBidAndFavoriteBid, editedUser,seteditedUser }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default Context;