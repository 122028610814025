import React from "react";
import {Link, useHistory} from "react-router-dom";
import path from "../../routes/path";
import Home from "./static/image/home.svg";
import Courses from "./static/image/courses.svg";
import News from "./static/image/news.svg";
import Contact from "./static/image/contact.svg";
import About from "./static/image/about.svg";
import "./static/StickyFooter.scss"

export const StickyFooter = () => {
    const history = useHistory();
    // console.log(history.location.pathname)
    let activeFooterTab = history.location.pathname;
    let activeClassBg = {
        backgroundColor: "#f5f5f5"
    }
    return(
        <div className="position-sticky footer-sticky w-100">
                <div className="row m-0">
                    <div className="col-ml-12 col-md-12 col-lg-12 col-sm-12 p-0">
                        <div className="d-flex justify-content-between align-content-center">
                            <Link to={path.home}>
                                <div style={activeFooterTab === "/" ? activeClassBg : null}
                                     className="home footer-div d-flex flex-column justify-content-center align-items-center ">
                                    <img src={Home} width={13} height={13} alt=""/>
                                    <h5 className={activeFooterTab === "/" ? "active-title" : "footer-navbar-title"}>Home</h5>
                                </div>
                            </Link>

                            <Link to={path.course_list}>
                                <div style={activeFooterTab === '/course-list' ? activeClassBg : null}
                                     className="course footer-div d-flex flex-column justify-content-center align-items-center">
                                    <img src={Courses} width={13} height={13} alt=""/>
                                    <h5 className={activeFooterTab === "/course-list" ? "active-title" : "footer-navbar-title"}>Courses</h5>
                                </div>
                            </Link>

                            <Link to={path.site_faq}>
                                <div style={activeFooterTab === '/site-faq' ? {backgroundColor: "#f5f5f5"} : null}
                                     className="news footer-div d-flex flex-column justify-content-center align-items-center site-faq">
                                    <img src={News} width={13} height={13} alt=""/>
                                    <h5 className={activeFooterTab === "/site-faq" ? "active-title" : "footer-navbar-title"}>Faq</h5>
                                </div>
                            </Link>

                            <Link to={path.contact_us}>
                                <div style={activeFooterTab === '/contact-us' ? {backgroundColor: "#f5f5f5"} : null}
                                     className="contacts footer-div d-flex flex-column justify-content-center align-items-center">
                                    <img src={Contact} width={13} height={13} alt=""/>
                                    <h5 className={activeFooterTab === "/contact-us" ? "active-title" : "footer-navbar-title"}>Contact</h5>
                                </div>
                            </Link>

                            <Link to={path.about_us}>
                                <div style={activeFooterTab === '/about-us' ? {backgroundColor: "#f5f5f5"} : null}
                                     className="about footer-div d-flex flex-column justify-content-center align-items-center">
                                    <img src={About} width={13} height={13} alt=""/>
                                    <h5 className={activeFooterTab === "/about-us" ? "active-title" : "footer-navbar-title"}>About</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
    );
}