import React from "react"
import CommonLayout from "../../containers/DefaultLayout/CommonLayout";
import "./static/404.scss"
import ErrorImage from "./static/images/404.png"
import Commonbutton from "../../components/button/Button";
import path from "../../routes/path";
import {useHistory} from "react-router-dom";

export const Error404 = () => {
    const history = useHistory();
    const redirectToHome = () => {
        history.push({
            pathname: `${path.profile}`,
        });
    }
    return (
        <div className="error-404-page">
            {/* <CommonLayout> */}
                <div className="error-404-page__wrapper">
                    <div className="container">
                        <div className="main-content d-flex justify-content-center">
                            <div className="text-center main-content__wrapper">
                                <img src={ErrorImage} alt="404 image"/>
                                <h1 className="page-title">Oh Sorry!</h1>
                                <h6>The page you were looking for doesn’t exist</h6>
                                <p>You might have typed in the wrong address or the page has been moved. In the
                                    meantime,
                                    try again or Return to the home page</p>
                                <div className="action-block w-100 mt-20 justify-content-center">
                                    <Commonbutton
                                        type="button"
                                        onclickCallback={redirectToHome}
                                        // disabled={}
                                        inputClassName="form-submit"
                                        // isLoading={isLoading}
                                        btnText="Home"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </CommonLayout> */}
        </div>
    )
}