import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Commonbutton from "../../../../components/button/Button";
import ApiUrlServices from "../../../../networks/ApiUrlServices";
import AxiosServices from "../../../../networks/AxiosService";
import { toast } from "react-toastify";
import {useFormik} from "formik";
import {Link, useHistory, useLocation} from "react-router-dom";
import path from "../../../../routes/path";
import constants, {localStorageKey} from "../../../../constants"
import CustomMultiSelectDropdown from "../../../../components/customMultiSelectDropdown/CustomMultiSelectDropdown";
import {useDispatch, useSelector} from "react-redux";
import {bid} from "../../../../services/types";
import AddRemoveFavBid from "../add-remove-fav/AddRemoveFavBid";
import "./static/ApplyBid.scss"
import { ThemeContext } from '../../../../ContextStore/Context';
import { useContext } from 'react';

const ApplyBid = props => {
    const { setMyBidAndFavoriteBid, myBidAndFavoriteBid } = useContext(ThemeContext)
    const [btnLoader,setBtnLoader] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const userBidRedux = useSelector(state => state.userBidReducer)
    const myBidRedux = userBidRedux[constants.localStorageKey.myBidData]
    // console.log(myBidRedux)
    // console.log(userBidRedux);
    const applyStatusEnum = [
        'applied', 'rejected', 'rewarded'
    ]
    const [myStatuses] = useState([
        {
            my_status: 'applied',
            label: "Applied"
        },
        {
            my_status: 'rejected',
            label: "Rejected"
        },
        {
            my_status: 'rewarded',
            label: "Rewarded"
        }
    ])

    const [myStatusesMap] = useState({
        "applied": {
            my_status: 'applied',
            label: "Applied"
        },
        "rejected": {
            my_status: 'rejected',
            label: "Rejected"
        },
        "rewarded": {
            my_status: 'rewarded',
            label: "Rewarded"
        }
    })
    const [status, setStatus] = useState(applyStatusEnum.map(s => ({
        label: `${s[0].toUpperCase()}${s.slice(1)}`,
        value: s
    })));

    const [isMyBid, setIsMyBid] = React.useState(false);
    React.useEffect(() => {
        applyBidFormik.setValues({my_bid_status: props.bid?.my_status ? [myStatusesMap[props.bid.my_status]] : []})
        setIsMyBid(props.bid.my_bid_id || myBidAndFavoriteBid.my_bid?.[props.bid.bid_id]);
    }, [])

    const sessionUserId = JSON.parse(localStorage.getItem('userData'))?.userId;
    const userBidData = JSON.parse(localStorage.getItem(constants.localStorageKey.userBidData));

    const [myBidData, setMyBidData] = React.useState(userBidData);


    const apply = async (values) => {
        // props.setPageLoader(true);
        setBtnLoader(true)
        const payload = {
            ...props.bid,
            my_status: values.my_bid_status[0].my_status,
        }

        AxiosServices.post(ApiUrlServices.ADD_MY_USER_BID(sessionUserId), payload, false)
            .then(res => {
                const myNewBid = {
                    [props.bid.bid_id]: res.data.data.my_bid_id
                };
                // props.setPageLoader(false);
                let newData = { ...myBidAndFavoriteBid }
                newData.my_bid[props.bid.bid_id] = res.data.data.my_bid_id
                setMyBidAndFavoriteBid(newData)
                setBtnLoader(false)
                dispatch({
                    type: bid.ADD_BID_TO_MY_BID,
                    payload: {...myNewBid}
                })
                toast.success(res.data?.message || "Success");
                history.push(path.my_bids)
                // history.goBack()
            })
            .catch(err => {
                toast.error("Something went wrong")
                // props.setPageLoader(false);
                setBtnLoader(false)
                console.log(err)
            })

    }

    const validateForm = (values) => {
        const errors = {};
        if (!values.my_bid_status?.length) {
            errors.my_bid_status = "This field is required"
        }
        return errors;
    }

    const formikField = {
        ...{...props.bid}
    }
    const applyBidFormik = useFormik({
        initialValues: {
            "my_bid_status": props.bid?.my_status ? [myStatusesMap[props.bid.my_status]] : []
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: apply,
    })

    function onSelectStatus(selectedList, selectedItem) {
        applyBidFormik.setValues(values => {
            values.my_bid_status = selectedList;
            return values;
        })
    }

    function onRemoveStatus(selectedList, selectedItem) {
        applyBidFormik.setValues(values => {
            values.my_bid_status = selectedList;
            return values;
        })
    }

    return (
        <>
            {console.log("myBidand favorite bid", myBidAndFavoriteBid, location.pathname, path.my_bid_detail, myBidAndFavoriteBid.my_bid?.[props.bid.bid_id])}
            {
                (isMyBid && location.pathname !== `${path.my_bid_detail}/${myBidAndFavoriteBid.my_bid?.[props.bid.bid_id]}`) ?
                    <Link to={`${path.my_bid_detail}/${myBidAndFavoriteBid.my_bid?.[props.bid.bid_id]}`}>
                        <button className={"btn btn-outline-dark mb-2"}>
                            <b>View My BID</b>
                        </button>
                        {console.log('viewMyBid',isMyBid,location.pathname)}
                    </Link>
                    :
                    <>
                    <div className='row align-items-center apply_bid'>
                        <div className='col col-7'>
                                <CustomMultiSelectDropdown
                                    id="my_bid_status"
                                    options={myStatuses}
                                    selectedValues={applyBidFormik.values?.my_bid_status}
                                    onSelect={onSelectStatus}
                                    onRemove={onRemoveStatus}
                                    selectionLimit={1}
                                    isSingle={true}
                                    asterisk={true}
                                    inputLabel="Status"
                                    displayValue="label"
                                    placeholder={!applyBidFormik.values?.my_bid_status?.length ? 'Select' : null}
                                    className={'text-capitalize'}
                                    requiredMessage={applyBidFormik.touched.my_bid_status && applyBidFormik.errors.my_bid_status}
                                    requiredMessageLabel={applyBidFormik.touched.my_bid_status || applyBidFormik.isSubmitting ? applyBidFormik.errors.my_bid_status : ""}
                                    loading={false}
                                    tooltip={true}
                                    tooltipText="You can select one status"
                                />
                        </div>
                        <div className='col-4 '>
                                <div className='action-block w-100 justify-content-between btn-section '>
                                    <Commonbutton
                                        type="button"
                                        disabled={applyBidFormik.isSubmitting}
                                        onclickCallback={applyBidFormik.handleSubmit}
                                        // inputClassName="btn btn-success "
                                        inputClassName="form-submit save-button"
                                        isLoading={btnLoader}
                                        btnText={props.btnText}
                                    />
                                </div>
                            </div>
                        </div>


                    </>}
        </>
    );
};

ApplyBid.propTypes = {
    bid: PropTypes.object.isRequired,
    setPageLoader: PropTypes.func.isRequired,
    btnText: PropTypes.string.isRequired
};

export default ApplyBid;

