import React, {lazy, Suspense, useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import { Error404 } from '../../app/error-pages/404';
import CustomPageLoader from '../../components/customPageLoader';
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import {StickyFooter} from "../../components/stickyFooter/StickyFooter";
import { ManageBothAccess, ManagePrivateAccess, ManagePublicAccess } from '../../routes/ManageAccess';
import path from '../../routes/path';
import ScrollToTop from '../../routes/ScrollToTop';
const Home = lazy(() => import('../../pages/Home/Home'));
const About = lazy(() => import('../../pages/About/About'));
const Contact = lazy(() => import('../../pages/Contact/Contact'));


 const CommonLayout = (props) => {

     const routes = [
         {
             accessRequired: 'both',
             path: path.client,
             component: Home,
         },
         {
             accessRequired: 'both',
             path: path.about_us,
             component: About,
         },
         {
             accessRequired: 'both',
             path: path.contact,
             component: Contact,
         },
    ]

    return (
        <div className="common-layout-main ">
            <Header data={props} cartCountIncrement={props.cartCountIncrement}/>

            <main id="main">
                <div className="main-body-container">
                    <div className="">
                        <ScrollToTop>
                            <Suspense fallback={<CustomPageLoader pageLoader={true} />}>
                                <Switch>
                                    {
                                        routes.map((route, i) =>
                                            (route.accessRequired === 'yes') ? (
                                                <ManagePrivateAccess
                                                    key={i}
                                                    path={route.path}
                                                    accessRequired={route.accessRequired}
                                                    exact
                                                    component={route.component}
                                                />
                                            ) : (route.accessRequired === 'no') ? (
                                                <ManagePublicAccess
                                                    key={i}
                                                    exact
                                                    path={route.path}
                                                    component={route.component}
                                                />
                                            ) : (
                                                <ManageBothAccess
                                                    key={i}
                                                    exact
                                                    path={route.path}
                                                    component={route.component}
                                                />
                                            )
                                        )
                                    }
                                    <Route component={Error404} />
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </div>
                </div>
            </main>

            <Footer/>
            {/*<StickyFooter />*/}


        </div>
    );
};
export default CommonLayout
