import React from 'react';
import Skeleton from 'react-loading-skeleton'
import './static/bidSkeleton.scss'
import 'react-loading-skeleton/dist/skeleton.css'


export const BidDetailsSkeleton = () => {
    return (
        <div>
            {/* <div className='skeleton'>
                <div className='ske_card px-4 py-3 my-4'>
                    <Skeleton height={60} count={1} />
                </div>
            </div> */}
            <div className='skeleton'>
                <div className='ske_card px-2 py-3 my-4'>
                    <Skeleton height={30} count={4} />
                </div>
            </div>
            
        </div>
    );
};

export const BidTableSkeleton=()=>{
    return (
        <div className=''>
            <div className='skeleton'>
                <div className='ske_card px-4 py-3 my-4'>
                    <Skeleton height={25} count={4} />
                    <Skeleton height={150} count={1} />
                </div>
            </div>
        </div>
    )
}

