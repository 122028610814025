import React from "react";
import { Redirect, Route, useLocation } from "react-router";
import path from "./path";

// export default function ManageAccess({ path, component: Component, ...rest }) {
//     return <Route path={path} {...rest} component={() => <Component />} />;
// }

const AUTH_USER_HOMEPAGE = path.profile;

export function ManagePrivateAccess({ path, component: Component, ...rest }) {
    const location = useLocation()
    console.log('location,',location)
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData)
        return <Route path={path} {...rest} component={() => <Component />} />;
    else{
        return <Redirect to={`/login?redirects_to=${location.pathname}`} />
    } 
}

export function ManagePublicAccess({ path, component: Component, ...rest }) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) return <Redirect to={AUTH_USER_HOMEPAGE} />;
    else return <Route path={path} {...rest} component={() => <Component />} />;
}

export function ManageBothAccess({ path, component: Component, ...rest }) {
    return <Route path={path} {...rest} component={() => <Component />} />;
}
