import {increment_value, decrement_value} from '../types'
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import {increment} from "../actions/counterAction";
import {useSelector, useDispatch} from "react-redux";


// let sessionUserData = JSON.parse(localStorage.getItem('userData'));
// let b=0;
// AxiosServices.get(ApiUrlServices.COUNT_CART_ITEM(sessionUserData.userId)).then(res => {
//     const dispatch = useDispatch()
//     dispatch(increment())
//     b = res.data.data.cart_count;
//     console.log(b)
// }).catch(err => {
//     console.log(err)
// })

const initialState = {
    count: 0
}

export default function (state = initialState, action) {
    console.log(state)
    console.log(action)
    switch (action.type) {
        case increment_value:
            return {
                ...state,
                count: state.count + 1
            }
        case decrement_value:
            return {
                ...state,
                count: state.count - 1
            }
        default:
            return state;
    }
}
