import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import { bid } from "../../services/types";
import path from "../../routes/path";
import {setSessionStorage} from '../../helper/CommonFunctions'
import { toast } from "react-toastify";


const getMyBidFavBid = (response, dispatch, history, setDataAdded,name,setMyBidAndFavoriteBid) => {

    if(history && !setMyBidAndFavoriteBid){
        if (name) {
            history.push(name)
        } else {
            if (response.data.data.is_preference_created) {
                history.push(path.dashboard);
            } else {
                const params = {
                    pathname: path.profile,
                    search: '?tab=Preferences',
                }
                history.push(params);
            }
        }
        toast.success('Login successfull')
        return
    }

    AxiosServices.get(ApiUrlServices.GET_MY_BID_FAV_BID(response?.data?.data?.userId))
         .then(res => {
             const data = res.data.data;
            setMyBidAndFavoriteBid && setMyBidAndFavoriteBid(res.data.data)
             // console.log(JSON.stringify(res, null, 2))
             dispatch && dispatch({
                 type: bid.FETCHED_BID_DATA,
                 payload: data,
             })
             localStorage.setItem("userBidData", JSON.stringify(data));
             
         })
         .catch(err => {
             console.log(err.response)
         })
         .finally(() => {
             let stored = response.status === 200 ? setSessionStorage("userData", response.data.data) : "";
             if (setDataAdded) setDataAdded(true);
             if(!history) return
         });
}

export default getMyBidFavBid;