import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from './routes/index';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './index.css';
import './scss/global.scss';
import './scss/custom.css';
import './scss/_responsive.scss'
import './app/profile/static/Profile.scss'
import 'react-loading-skeleton/dist/skeleton.css'


import store from './store';
import path from "./routes/path";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import axios from 'axios';
import Amplify from 'aws-amplify';
import { S3_Pool_conf } from './config/aws-amplify';
import { API_URL } from './config';
Amplify.configure(S3_Pool_conf)

let persistor = persistStore(store);

axios.interceptors.request.use(request => {

    if (request.url.includes("course/courses/get_permission") && request.method === "post") {
        request.headers.withCredentials = true;
    }
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});


axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 || error.response.data.code === "USER_NOT_MENTOR") {
        let usersData = JSON.parse(localStorage.getItem("userData"));
        const refreshToken = usersData.refreshToken;
        return axios.post(`${API_URL.api_base_url}/auth/authentication/refresh_token`,
            JSON.stringify({
                "refresh_token": refreshToken
            }))
            .then(response => {

                usersData["accessToken"] = response.data.data.accessToken
                usersData["idToken"] = response.data.data.idToken

                localStorage.setItem("userData", JSON.stringify(usersData));

                error.response.config.headers['Authorization'] = `Bearer ${response.data.data.idToken}`;

                return axios(error.response.config);
            })
            .catch(error => {
                localStorage.clear()
                document.cookie.split(";").forEach(function (c) {
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });
                window.location = path.login;
            });
        return Promise.reject(error);
    }
    return Promise.reject(error);


});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <MainRoutes />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

